import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Popover, useDisclosure } from '@chakra-ui/react';
import { DatePickerProps } from '../datepicker.component';
import { useDatepicker } from '@datepicker-react/hooks';
import {
  DatePickerContext,
  defaultDateFormat,
  defaultFocusedDate,
  defaultMaxYear,
  defaultMinYear,
  FocusedInput,
  today,
} from './datepicker.context';

export interface DatePickerProviderProps extends DatePickerProps {
  children: React.ReactNode;
  calendarRef: RefObject<HTMLDivElement>;
}

interface DatePickerInternalState {
  startDate: Date | null;
  endDate: Date | null;
  focusedInput: FocusedInput | null;
}

export const DatePickerProvider = ({
  children,
  dateFormat = defaultDateFormat,
  endDate,
  inputProps,
  isDateRange,
  maxDate,
  maxYear,
  minDate,
  minYear,
  onDateChange,
  placement,
  startDate,
  calendarRef,
}: DatePickerProviderProps): JSX.Element => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [state, setState] = useState<DatePickerInternalState>({
    startDate,
    endDate: endDate || startDate,
    focusedInput: null,
  });

  const onDatesChange = (data: DatePickerInternalState): void => {
    if (!data) {
      return;
    }

    setState({ ...data, focusedInput: data.focusedInput || FocusedInput.StartDate });

    if (onDateChange) {
      onDateChange(data);
    }
  };

  const {
    activeMonths,
    firstDayOfWeek,
    focusedDate,
    goToDate,
    goToNextMonths,
    goToPreviousMonths,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
  } = useDatepicker({
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    initialVisibleMonth: state.startDate || today,
    maxBookingDate: maxDate,
    minBookingDate: minDate,
    minBookingDays: 1,
    numberOfMonths: 1,
    onDatesChange,
    startDate: state.startDate,
    ...(!isDateRange && { exactMinBookingDays: true }),
  });

  const navigateToFocusedInputDate = useCallback(
    (focusedInput: FocusedInput | null) => {
      if (focusedInput === FocusedInput.EndDate && state.endDate) {
        goToDate(state.endDate);
      } else if (state.startDate) {
        goToDate(state.startDate);
      }
    },
    [goToDate, state.endDate, state.startDate],
  );

  const setFocusedInput = useCallback(
    (focusedInput: FocusedInput | null): void => {
      setState((prevState) => ({ ...prevState, focusedInput }));
      navigateToFocusedInputDate(focusedInput);
    },
    [navigateToFocusedInputDate],
  );

  const handleOpen = (): void => {
    if (inputProps?.isDisabled) return;

    onOpen();

    // Default to start date input on open if no start date value has been set
    if (!state.startDate || !isDateRange) {
      setFocusedInput(FocusedInput.StartDate);
    }
  };

  const handleClose = useCallback((): void => {
    setFocusedInput(null);
    onClose();
  }, [onClose, setFocusedInput]);

  const activeMonth = useMemo(() => activeMonths[0], [activeMonths]);

  // Prevent closing when using the month navigation buttons
  useEffect(() => {
    const handleClick = (event: MouseEvent): void => {
      if (calendarRef && !calendarRef?.current?.contains(event?.target as Node)) {
        handleClose();
      }
    };
    document.addEventListener('mousedown', handleClick);

    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClose, calendarRef]);

  return (
    <DatePickerContext.Provider
      value={{
        activeMonth,
        activeMonths,
        dateFormat: dateFormat || defaultDateFormat,
        endDate: state.endDate || null,
        firstDayOfWeek,
        focusedDate: focusedDate || defaultFocusedDate,
        focusedInput: state.focusedInput,
        goToDate,
        goToNextMonths,
        goToPreviousMonths,
        setFocusedInput,
        isDateBlocked,
        isDateFocused,
        isDateHovered,
        isDateRange: isDateRange || false,
        isDateSelected,
        isFirstOrLastSelectedDate,
        maxDate,
        maxYear: maxYear || defaultMaxYear,
        minDate,
        minYear: minYear || defaultMinYear,
        onDateChange,
        onDateFocus,
        onDateHover,
        onDateSelect,
        startDate: state.startDate,
        isOpen,
        onOpen,
        onClose: handleClose,
      }}
    >
      <Popover isOpen={isOpen} onClose={handleClose} onOpen={handleOpen} placement={placement}>
        {children}
      </Popover>
    </DatePickerContext.Provider>
  );
};
