import { FirstDayOfWeek, MonthType } from '@datepicker-react/hooks';
import { useContext, createContext } from 'react';
import { DateRange } from '../datepicker.component';
import { DateFormats } from '../datepicker.types';

export enum FocusedInput {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export interface IDatePickerContext {
  activeMonth?: MonthType;
  dateFormat: DateFormats | string;
  endDate: Date | null;
  focusedInput: FocusedInput | null;
  setFocusedInput: (focusedInput: FocusedInput | null) => void;
  isDateRange: boolean;
  maxDate?: Date;
  maxYear: number;
  minDate?: Date;
  minYear: number;
  onDateChange?: ({ startDate, endDate }: DateRange) => void;
  startDate: Date | null;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  // The following are all values returned by the datepicker-react useDatePicker hook
  // Setting them here in our own context to be referenced in child components
  focusedDate: Date | null;
  firstDayOfWeek?: FirstDayOfWeek;
  activeMonths: MonthType[];
  isDateFocused: (date: Date) => boolean;
  isDateSelected: (date: Date) => boolean;
  isDateHovered: (date: Date) => boolean;
  isDateBlocked: (date: Date) => boolean;
  isFirstOrLastSelectedDate: (date: Date) => boolean;
  onDateFocus: (date: Date) => void;
  onDateHover: (date: Date | null) => void;
  onDateSelect: (date: Date) => void;
  goToPreviousMonths: () => void;
  goToNextMonths: () => void;
  goToDate: (date: Date) => void;
}

export const today = new Date();
export const currentYear = today.getFullYear();
export const currentMonth = today.getMonth();

export const defaultFocusedDate = today;
export const defaultMinYear = currentYear - 100;
export const defaultMaxYear = currentYear + 5;
export const defaultDateFormat = DateFormats.ShortDate;

const initialDatePickerState: IDatePickerContext = {
  dateFormat: defaultDateFormat,
  endDate: null,
  focusedInput: null,
  isDateRange: false,
  maxYear: defaultMaxYear,
  minYear: defaultMinYear,
  startDate: null,
  isOpen: false,
  onOpen: (): void => {
    throw new Error('onOpen(): boolean not implemented');
  },
  onClose: (): void => {
    throw new Error('onClose(): boolean not implemented');
  },
  setFocusedInput: (): void => {
    throw new Error('setFocusedInput(): boolean not implemented');
  },
  focusedDate: today,
  firstDayOfWeek: undefined,
  activeMonths: [],
  isDateFocused: (): boolean => {
    throw new Error('isDateFocused(): boolean not implemented');
  },
  isDateSelected: (): boolean => {
    throw new Error('isDateSelected(): boolean not implemented');
  },
  isDateHovered: (): boolean => {
    throw new Error('isDateHovered(): boolean not implemented');
  },
  isDateBlocked: (): boolean => {
    throw new Error('isDateBlocked(): boolean not implemented');
  },
  isFirstOrLastSelectedDate: (): boolean => {
    throw new Error('isFirstOrLastSelectedDate(): boolean not implemented');
  },
  onDateFocus: (): void => {
    throw new Error('onDateFocus(): boolean not implemented');
  },
  onDateHover: (): void => {
    throw new Error('onDateHover(): boolean not implemented');
  },
  onDateSelect: (): Date => {
    throw new Error('onDateSelect(): boolean not implemented');
  },
  goToPreviousMonths: (): Date => {
    throw new Error('goToPreviousMonths(): boolean not implemented');
  },
  goToNextMonths: (): Date => {
    throw new Error('goToNextMonths(): boolean not implemented');
  },
  goToDate: (): Date => {
    throw new Error('goToDate(): boolean not implemented');
  },
};

export const DatePickerContext = createContext<IDatePickerContext>(initialDatePickerState);

export const useDatePickerContext = (): IDatePickerContext => useContext<IDatePickerContext>(DatePickerContext);
